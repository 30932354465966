import { useTranslation } from "react-i18next";
import Popup from "../../helpers/Popup";

const HowTORedeemPopup = () => {

    return (
        <Popup className="howtoredeem-us-popup" title="HOW TO REDEEM">
            <div style={{ textAlign: "left" }}>

                <p dir="ltr">
                    1. Scan the QR code or visit avatarsrolacola .com
                </p>
                <p dir="ltr">
                    2. Enter the unique code printed inside the pack, along with your name, email ID,mobile number, and consent to T&Cs and click on submit to receive the OTP.
                </p>
                <p dir="ltr">
                    3. Enter the OTP and click on Submit to complete your registration
                </p>
                <p dir="ltr">
                    4. You will stand a chance to win exciting rewards

                </p>
            </div>


        </Popup>
    );
};

export default HowTORedeemPopup;