import { useTranslation } from "react-i18next";
import Popup from "../../helpers/Popup";
import "./index.scss";

const TermsPopup = () => {
  const { t } = useTranslation();

  return (
    <Popup className="terms-popup" title="Terms & Conditions">
     
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Contest Details, Concept and Participation: <br/>
        </p>
    </li>
</ol>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            These terms and conditions apply to the "Parle Rol a cola Consumer
            promo" program for customers (hereinafter referred to as Contest")
            sponsored and launched by Parle Products Pvt. Ltd, wherein customers
            of Parle Rol a cola Consumer promo can participate by scanning a QR
            Code printed on the specially marked promo packs of Parle Rol a cola
            Consumer promo range of products.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            The contest is valid PAN India with the exception of the state of
            Tamil Nadu from 25/12/2024 to 22/02/2025 (hereinafter referred as
            "Contest Period").
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            During the Contest Period:
        </p>
    </li>
</ol>
<ul>
    <li dir="ltr" style={{textAlign:"left"}}>
        <p dir="ltr">
            12 winners every day will be eligible to win INR 250 Gift Vouchers
            as per below bifurcation: <br/>
            <br/>
            2 winners: Amazon GV <br/>
            2 winners: Myntra GV <br/>
            2 winners: Book My Show GV <br/>
            4 winners: Make My Trip GV <br/>
            2 winners: Reliance Digital GV
        </p>
    </li>
</ul>
<br/>
<ul>
    <li dir="ltr" style={{textAlign:"left"}}>
        <p dir="ltr">
            A total of 720 winners will be selected during the promotion period,
            2 winners from each SKU
        </p>
    </li>
</ul>
<ol start={4}>
    <li dir="ltr">
        <p dir="ltr">
            All IPR rights including trademark, copyright, and designs used are
            the property of their respective owners.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            The Contest is subject to all applicable central, state and local
            laws and regulations.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Customers of Parle Rol a cola Consumer promo participating or
            seeking to participate in the Contest shall individually be referred
            to as "Participant" and collectively as "Participants".
        </p>
    </li>
</ol>
<ol start={2}>
    <li dir="ltr">
        <p dir="ltr">
            Agreement to Terms and Conditions: <br/>
            <br/>
        </p>
    </li>
</ol>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            By participating in this contest, Participants fully and
            unconditionally agree to and accept these Terms and Conditions
            available on avatarsrolacola.com "Contest Website". The decisions of
            Parle Products Pvt. Ltd will be final and binding in all matters
            related to the Contest. Successfully entering the Contest and
            winning a prize is subject to all requirements set forth herein.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Parle Products Pvt. Ltd reserves the right to exclude any person
            from the contest on grounds of misconduct or criminal record or for
            any other reasons, as it may deem fit and proper. No communication
            in the regard will be entertained. Parle Products Pvt. Ltd reserves
            the right to terminate, modify or extend the Contest and/or deletion
            or addition of any of the terms and conditions of the Promo, at any
            time at its absolute discretion, without advance notice and/or
            assigning any reason thereof. All decisions of Parle Products Pvt.
            Ltd in respect of the Contest and the prizes therein will be final,
            binding and conclusive. <br/>
            <br/>
        </p>
    </li>
</ol>
<ol start={3}>
    <li dir="ltr">
        <p dir="ltr">
            Eligibility: <br/>
            <br/>
        </p>
    </li>
</ol>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            This Contest will be open to customers of Parle Products Pvt. Ltd
            who are residents of the India except for the state of Tamil Nadu
            and employees, their immediate family members of Parle Products Pvt.
            Ltd, and the agency.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            This contest is open for adults above 18 years.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Parle Products Pvt. Ltd or its Agency will not be responsible for
            any NDNC (National Do Not Call) Registry regulation that will come
            into play. All Participants who participate will agree as per the
            terms and conditions that even if they are registered under NDNC,
            DND (Do Not Disturb), Parle Products Pvt. Ltd or its Agency will
            have all the authority to call such shortlisted winners by virtue of
            them having voluntarily participated in the Contest.
        </p>
    </li>
</ol>
<ol start={4}>
    <li dir="ltr">
        <p dir="ltr">
            Contest Period &amp; Contest Timings: <br/>
            <br/>
        </p>
    </li>
</ol>
<p dir="ltr">
    The contest will commence from 12:01:01 AM on 25/12/2024 to 11:59:59 PM on
    22/02/2025
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            720 winners will be selected between 12:01:01 AM on 25/12/2024 to
            11:59:59 PM on 22/02/2025 during the contest period.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Time of participation will be defined as the time at which the entry
            is received through the promotional website mode of participation at
            Parle Products Pvt. Ltd.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Entries coming outside the Contest Period will not be considered for
            winner selection.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            The contest shall run on the following SKUs of Rol-a-Cola: <br/>
            Rol-a-cCola (Rs.5, 25g) <br/>
            <br/>
        </p>
    </li>
</ol>
<ol start={5}>
    <li dir="ltr">
        <p dir="ltr">
            Prizes: <br/>
            <br/>
        </p>
    </li>
</ol>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            During the Contest Period, winners will be selected for a Rs 250
            Gift Voucher. Therefore, there will be a total of 720 such prizes
            which will be declared during the contest period. <br/>
            <br/>
        </p>
    </li>
</ol>
<ol start={6}>
    <li dir="ltr">
        <p dir="ltr">
            How to Participate: <br/>
            <br/>
        </p>
    </li>
</ol>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Any participant can enter any number of times during the contest
            period from a mobile number.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Scan the QR code or visit avatarsrolacola.com
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Customer will be asked to complete their registration by entering
            the unique code printed inside the pack, along with their name,
            email ID, mobile number, and consent to T&amp;Cs.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Upon submitting the details, the customers will receive an OTP on
            their registered mobile number. Customers will need to submit the
            OTP to authenticate their mobile number.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Once the customer successfully completes their registration, they
            will stand a chance to win exciting rewards.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Parle Products Pvt. Ltd and the Partner Agency shall not be
            responsible for (including but not limited to):
        </p>
    </li>
</ol>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            For any SPAM generated messages
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            For the Operator Code not being displayed on the user's mobile
            phones
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            For any SMS message delivery failures
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Any lost, late or misdirected computer transmission or network,
            electronic failures or any kind of any failure to receive entries
            owing to transmission failures or due to any technical reason
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            If the participant has registered himself to the DND of the telecom
            provider/ the participant has registered with National Do Not Call
            Registry/
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Participant has specifically requested for not receiving messages
            for the specific campaign
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Other conditions beyond its reasonable control
        </p>
    </li>
</ol>
<ol start={7}>
    <li dir="ltr">
        <p dir="ltr">
            Parle Products Pvt. Ltd or Premier Sales Promotions Pvt Ltd shall
            not be responsible for any charges, levies, costs incurred by the
            end consumer to participate in this contest.
        </p>
    </li>
</ol>
<ol start={7}>
    <li dir="ltr">
        <p dir="ltr">
            Winner Selection: <br/>
            <br/>
        </p>
    </li>
</ol>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Any participant can win a max of 1 (one) Rs 250 Gift voucher during
            the contest period from a mobile number, email ID, address, or
            household.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            From the Valid Entries received, the Organizer will select 720
            winners who will win Prizes throughout the Promotion Period
            (“Winners”)
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Winners will be selected by the Organizers using randomizer software
            irrespective of the time of the entry.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Prizes will be given subject to the winner satisfying the
            verification process of Parle Products Pvt. Ltd
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            The Prize cannot be transferred, exchanged or redeemed for cash. In
            the event that the winner does not take the prize in the time and
            manner stipulated, then the prize will be forfeited by the winner
            and cash will not be awarded in lieu of the prize. Prizes must be
            taken as offered and cannot be varied. Any unclaimed prize is
            not-transferable, non-refundable and non-redeemable for other goods
            or services and cannot be taken in exchange for cash.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Any unused prize is not-transferable, non-refundable and
            non-redeemable for other goods or services and cannot be taken in
            exchange for cash.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Parle Products Pvt. Ltd shall provide the prize on an "as-is basis"
            and without any warranty or guarantee concerning the quality,
            suitability or comfort, and Parle Products Pvt. Ltd and/or its
            associates, affiliates and/ or its management, directors, officers,
            agents, representatives shall not be responsible for or liable
            (including but not limited to the product and service liabilities)
            for deficiency and/ or defect of any product/service and/or the
            prize or for any kind of consequential damages/ loss, in any manner
            whatsoever. If any Participant has any grievance with respect to the
            prize, he/she may directly contact the manufacturer.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            The image of the prize depicted on the press ads/posters/TVC's/pack
            shots etc. are for illustrative purposes only and the actual prize
            may vary from the depiction made.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Parle Products Pvt. Ltd or its Agencies shall not be liable for any
            loss or damage of the prize due to Act of God, Governmental action,
            Force Majeure circumstances and / or any other reasons which are
            beyond the reasonable control of Parle Products Pvt. Ltd/ its
            Agency, and under no circumstances, Parle Products Pvt. Ltd shall be
            liable to pay any compensation whatsoever for such losses.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Any participant of the contest who wins once during the Contest
            Period shall not be eligible to win any further prizes.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            The winner generation for Mega Prizes will be done on the basis of
            Valid Unique mobile number. <br/>
            <br/>
        </p>
    </li>
</ol>
<ol start={8}>
    <li dir="ltr">
        <p dir="ltr">
            Publicity: <br/>
            <br/>
        </p>
    </li>
</ol>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            The Participant/s undertake and irrevocably and unconditionally
            permit Parle Products Pvt. Ltd to cover the Contest through various
            media including newspapers, radio television news channels,
            internet, point of sale materials, etc., and shall not raise any
            objection, protest or demur to such coverage or in this regard.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            The winners must, at the request of Parle Products Pvt. Ltd,
            participate in all promotional activity (such as publicity and
            photography) surrounding the winning of the prize, free of charge,
            and they consent to Parle Products Pvt. Ltd using their name and
            image in promotional material.
        </p>
    </li>
</ol>
<ol start={9}>
    <li dir="ltr">
        <p dir="ltr">
            Intellectual Property: <br/>
            <br/>
        </p>
    </li>
</ol>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            All right, title and interest, including but not limited to the
            Intellectual Property Rights, in the promotional material(s) and in
            any and all responses received shall vest solely and exclusively
            with Parle Products Pvt. Ltd at all times. Parle Products Pvt. Ltd
            or any person or entity permitted by Parle Products Pvt. Ltd in this
            regard shall be entitled to use the responses received or any
            information in connection with the entry in any media for future
            promotional, marketing, publicity and any other purpose, without any
            permission and or payment to the Participant.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            All material submitted in connection with the Contest (whether
            written, audio, electronic or visual form, or a combination of
            those) or any photographs, video and/or film footage and/or audio
            recording taken of Participants are assigned to Parle Products Pvt.
            Ltd upon submission and become the property of Parle Products Pvt.
            Ltd exclusively. Parle Products Pvt. Ltd may use the material in any
            medium in any reasonable manner it sees fit. Copyright in any such
            material remains the sole property of Parle Products Pvt. Ltd <br/>
            <br/>
        </p>
    </li>
</ol>
<h3 dir="ltr">
    General Terms &amp; Conditions of the Contest:
</h3>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Each Participant represents and warrants that he/she is legally
            competent to enter into binding contracts under applicable laws. By
            taking part and/or entering into the Contest the Participant
            warrants that all information provided by Participant regarding
            Participant's name, age, state, city, address, phone number, etc.,
            is true, correct, accurate and complete.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            The decision of Parle Products Pvt. Ltd or its Agency regarding the
            selection of winners and distribution of the prizes shall be final,
            subject only to Parle Products Pvt. Ltd 's approval. No
            correspondence or any other claims whatsoever, in this regard will
            be entertained.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            No queries, claims, dispute, complaints or grievances shall be
            entertained by Parle Products Pvt. Ltd and its agency after 30 days
            from the date of the closure of the contest.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            A participant can win a maximum of 1 prize during the contest
            period. If a participant has won a Myntra GV or Amazon Pay GV or
            Make My Trip GV or Reliance Digital GV or Book My Show GV then the
            participant will not be eligible to win any further prizes.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            All applicable taxes will be borne by the winner.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Wherever Gift Tax is applicable, the amount will be borne by the
            customer.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            The prize cannot be exchanged and is not transferable and cannot be
            converted into cash.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            The Agency shall provide the prize on an "as-is basis" and without
            any warranty or guarantee concerning the quality, suitability or
            comfort, and Parle Products Pvt. Ltd and/or its associates,
            affiliates and/ or its management, directors, officers, agents,
            representatives shall not be responsible for or liable (including
            but not limited to the product and service liabilities) for
            deficiency and/ or defect of any product/service and/or the prize or
            for any kind of consequential damages/ loss, in any manner
            whatsoever. If any Participant has any grievance with respect to the
            prize, he/she may directly contact the manufacturer.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Parle Products Pvt. Ltd reserves the right to change, defer, alter
            or cancel this Contest in part or full, or change any or all of the
            terms and conditions that are applicable, without giving prior
            intimation/notice of any kind and will not be liable for any
            consequential losses/damages. Any decision of Parle Products Pvt.
            Ltd in this regard and in general with respect to this Contest and
            the terms and conditions thereto shall be final, binding and
            conclusive.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Nothing contained herein amounts to a commitment or representation
            by Parle Products Pvt. Ltd to conduct further Contests.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            The decision of Parle Products Pvt. Ltd shall be final in all
            regards and no communication shall be entertained in this regard.
            None of the provisions of these Terms and Conditions shall be deemed
            to constitute a partnership or agency between any Participant and
            Parle Products Pvt. Ltd and the Participant shall not have the
            authority to bind Parle Products Pvt. Ltd in any manner whatsoever.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Parle Products Pvt. Ltd or any of its respective
            officers/employees/directors shall not be responsible for delayed,
            lost, mutilated, corrupted or illegible documents etc.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Parle Products Pvt. Ltd or its Agency shall not be liable to perform
            any of its/their respective obligations under the Contest or in
            respect of the prize where it is unable to do so as a result of
            circumstances beyond its/their control in the nature of fire,
            explosions, natural calamities, state emergency, riots, or any other
            Force Majeure condition, etc., and shall not be liable to compensate
            the Participant in these circumstances.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Parle Products Pvt. Ltd or it’s agency shall not be
            accountable/liable for any disruptions/stoppages/interruptions or
            cancellation of the Contest due to any government restrictions.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Failure to exercise or delay in exercising a right or remedy
            provided hereunder or by law does not constitute a waiver of the
            right or remedy or waiver of other rights or remedies on the part of
            Parle Products Pvt. Ltd or its Agency.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Parle Products Pvt. Ltd or its agency accepts no responsibility for
            late, lost, incomplete, incorrectly submitted, delayed, illegible,
            corrupted or misdirected entries, loss of SMS, loss of internet
            connectivity, unique codes, documents, demand drafts, claims or
            correspondence whether due to error, omission, alteration,
            tampering, unauthorized data hacking, deletion, theft, destruction,
            virus attack, transmission interruption, communications failure,
            hardware failure or otherwise. Parle Products Pvt. Ltd or it’s
            agency shall not be liable for any consequences of user error
            including (without limitation) costs incurred.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            If a Participant is dissatisfied with the Contest or the Contest
            rules and/or any content or any requirements of the Contest form,
            the materials available related thereto, or with these terms and
            conditions, his/her sole and exclusive remedy is to not participate
            in the Contest.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            If a person chooses to access the Contest Website from outside
            India, he/she shall do so on his/her own initiative and shall be
            responsible for compliance with applicable local laws of the
            country.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            The operational part of the scheme like delivery of gift shall be
            managed by the Premier Sales Promotions Pvt. Ltd; However, product
            will be distributed through Parle Products Pvt. Ltd
            Distributor/dealer.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            The participation in the Contest is purely voluntary and the same
            shall be construed as an acceptance of the terms and conditions
            stipulated herein.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Validity of Prize cannot be extended, OR new Prize cannot be
            provided against the expired/unused Vouchers.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            All disputes arising out of or in connection to this scheme are
            subject to exclusive jurisdiction of the courts in Bangalore only.
            <br/>
            <br/>
        </p>
    </li>
</ol>
<p dir="ltr">
    Myntra - Terms &amp; Conditions
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            This E-Gift Card is redeemable only on Myntra marketplace platform.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            This E-Gift Card shall have a minimum validity period of 6 months.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            This E - Gift Card cannot be used to purchase other gift cards.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Multiple E-Gift Cards can be clubbed in a single order. E -Gift
            Cards cannot be redeemed for Cash or Credit and cannot be reloaded.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            This E-Gift Card can be redeemed online against sellers listed on
            www.myntra.com or Myntra
        </p>
    </li>
</ol>
<p dir="ltr">
    Mobile App only. In cases where the order is cancelled, the E- Gift Card
    amount shall be refunded to the source EGift Card.The E - Gift Card amount
    shall not be refunded to the user's cashback account.
</p>
<ol start={6}>
    <li dir="ltr">
        <p dir="ltr">
            The balance amount, if any, of the cost of the item being purchased
            by the Customer, after the discount has been availed, will have to
            be paid by the Customer at the time of purchase. If the order value
            exceeds the E -Gift Card amount, the balance must be paid by Credit
            Card/Debit Card/Internet Banking/Cash on Delivery.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Extra VAT may apply in addition to the unit sale price on certain
            products, but the final inclusive price will not exceed the 'M.R.P.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            All taxes, duties, levies or other statutory dues and charges
            payable in connection with the benefits accruing under the offer
            shall be All forms of taxes, including gift tax, income tax etc. if
            applicable will be borne by the winner.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            If the order value is less than the amount of the E - Gift Card, the
            outstanding balance (after deduction of order value) will reflect
            under the same E -Gift Card and can be used for subsequent
            transactions.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            For individual retail customers E - Gift Cards can be purchased on
            www.myntra.com or Myntra Mobile App using the following payment
            modes only - Credit Card, Debit Card, and Net Banking.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Gift Cards can be redeemed by selecting the payment mode as Gift
            Card.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            You are solely responsible for the safety and security of the E
            -Gift Cards. myntra.com is not responsible for any acts of omission
            or commission if Gift Card is lost, stolen, or used without
            permission.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Once the E - Gift Card has been sent to the customer, then the
            customers are bound to protect the E-Gift Card PIN or E -Gift Card
            number. In the event of any misuse of E -Gift Card due to loss of
            any such confidential details due to the fault of the
            purchaser/customer, myntra.com shall not be held responsible for the
            same and no refund can be issued against such E -Gift Cards.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Myntra.com assume no responsibility for the products purchased using
            the E - Gift Card and any liability thereof is expressly disclaimed.
            Product warranty shall be regulated by the terms and conditions
            mentioned on the warranty card of respective products.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Validity of E - Gift Card cannot be extended, new E - Gift Card
            cannot be provided against the expired/unused E - Gift Cards.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Myntra reserves the right to cancel the E - Gift Card if the same
            have been found to be purchased with fraudulent means and/or the
            beneficiary/Know Your Customer ("KYC") details as per RBI Guidelines
            are found to be incorrect/insufficient. In such cases, the funds
            shall be credited back to the same source from where these were
            received.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            You agree and understand that E - Gift Cards are a pre-paid payment
            Instrument subjected to regulations by RBI Under the RBI guidelines,
            Myntra may be required to share KYC details of the purchaser/
            redeemer of the Gift Card and/or any other information with relation
            to the purchase of the Gift Cards and/or transaction undertaken
            using the Gift Card with RBI or such statutory authorities. Myntra
            may contact the purchaser/redeemer of the GC for any such
            information.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Myntra along with its sellers reserves the right to change the terms
            and conditions of the E-Gift Card, including extending, withdrawing,
            or discontinuing the same without notice, at its sole discretion.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            All orders would be subject to availability at the 􀀅me of purchase
            and will be governed by the standard terms and conditions on
            myntra.com. In case of return of the purchased item, please refer to
            the “Return Policy” on our website or write to
            <a href="mailto:returns@myntra.com">
                returns@myntra.com
            </a>
            .
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            All disputes arising out of or in connection to this scheme are
            subject to exclusive jurisdiction of the courts in Bangalore only.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Gift Cards are not applicable on the following sellers: Titan
            Company Limited, Bluestone Jewellery &amp; Lifestyle Pvt. Ltd.,
            Aditya Birla Fashion &amp; Retail Pvt. Ltd.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            This E-Gift Card can be redeemed online against all sellers listed
            on www.myntra.com or Myntra Mobile App only except items sold by
            TITAN company Ltd, BlueStone Jewellery and Lifestyle Pvt Ltd and
            Aditya Birla Fashion and Retail Limited. <br/>
            <br/>
            <br/>
        </p>
    </li>
</ol>
<p dir="ltr">
    BookMyShow - Terms &amp; Conditions <br/>
    <br/>
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            This offer entitles the end user to Rs. 250 off on one movie
            transaction at the BookMyShow website /App. The offer will be
            mentioned on the scratch card.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Offer valid for all shows from Monday to Sunday.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Discount is offered on a minimum of 1 movie ticket and on a maximum
            of 1 transaction only irrespective of the number of tickets booked
            on one promo Code. The balance cost (after discount) of the ticket
            will be charged to the credit/ debit card of the end-user.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            The end user will be required to present a copy of the credit/debit
            card at the cinema hall prior to redemption.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            To avail of the offer, customers to show the SMS confirmation or a
            printout of the e-ticket at the venue.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            If the value of the movie ticket is less than the value of the
            voucher, the balance is nonrefundable and cannot be carried forward
            or used in any other transaction.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Walk-in customers without a prior booking will not be entertained at
            the venue.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Film classification rules apply, and identification may be required.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            The theatre manager’s decision is final and standard cinema rules
            apply.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            This offer is subject to promotional availability.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            In no event shall the maximum liability exceed the value of the
            voucher i.e. Rs.250 <br/>
            <br/>
        </p>
    </li>
</ol>
<p dir="ltr">
    MakeMyTrip - Terms &amp; Conditions
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            The Gift card is valid for purchases made from MakeMyTrip only and
            is valid for a period of 6 Months from the date of purchase.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            For Flights, Hotels &amp; Holidays: <br/>
            a. Can be redeemed online at www.makemytrip.com or on MakeMyTrip
            Android &amp; IOS app. Please follow the steps listed below: <br/>
            <br/>
            i. Select your Flight/ Hotel and fill required details will you
            reach the payment page <br/>
            ii. On Website, click on "More options" and Select "Gift Card" as
            your Payment Mode. On Android    and IOS app, choose Gift Card as
            the payment option <br/>
            iii. Enter your Gift Card/ Card No. (16 Digit) and 6 Digit Pin No.
            <br/>
            iv. Click on "Make Payment" and Pay the Balance amount (if any)
            using other Payment Modes Listed. <br/>
            v. Flights &amp; Hotels are not redeemable offline
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            For redeeming Holidays offline through our Holiday Experts: <br/>
            <br/>
            a. Please call on the understated number to redeem the card. <br/>
            b. Domestic and International Holiday packages: 0124- 4859657. <br/>
            c. You can reach out to our travel expert between 9 AM to 9 PM <br/>
            d. You can write on gifts@makemytrip.com <br/>
            e. You can also visit the MakeMyTrip Branches to redeem. <br/>
            <br/>
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            This Gift card is not valid on Rail, Homestays and VISA.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Gift cards are valid on bookings made through MMT Mobile App.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            In case of transaction failures after Gift Card is applied, amount
            would be automatically refunded to the same cards within 24 hours.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            This Gift card/voucher cannot be clubbed with any other ongoing
            offer discount/cash back/promotion run by Makemytrip.com on app or
            website.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            This Gift card is valid for partial redemption. Balance would remain
            in the Gift Card till the validity period and can be reused for
            multiple transactions.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Multiple Gift Cards (up to 3) can be combined and used on 1
            transaction.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Products and services are subject to availability.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            The Gift card cannot be cancelled or exchanged for cash.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            The Gift card validity cannot be extended under any circumstances.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Gift Card code/Physical copy or both to be provided at the time of
            booking along with an ID proof for the offline redemption.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            MakeMyTrip is not responsible if the Gift card is lost, stolen or
            used without permission.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            In case of cancellation of bookings made using the gift card within
            the validity period, the amount will be reversed to the same card as
            used at the time of booking.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Users are required to save the gift card number and PIN to utilize
            this refunded amount as we will not be able to reset the PIN or
            reissue a new gift card number.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            In case of cancellations where the gift card validity has expired,
            no refund will be processed for the amount paid by the gift card.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            MakeMyTrip is the final authority on the interpretation of these
            rules
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            MakeMyTrip reserves the right to deny accepting any Gift card if it
            suspects that there is duplicity of cards.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            In no event the liability of MakeMyTrip for any claims shall exceed
            the value of the card/voucher.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            In the event of any dispute, parties agree to exclusive jurisdiction
            of courts of New Delhi.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            This is for individual use only, Travel agents found using the card
            would lead to cancellation of booking and no refund would be made.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            For any issue or query regarding Gift card, please write to Gift
            vouchers@makemytrip.com
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            In no event shall the maximum liability exceed the value of the
            Voucher i.e. Rs. 250
        </p>
    </li>
</ol>
<p dir="ltr">
    Terms &amp; Conditions - Amazon Pay Gift Voucher
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Amazon Gift Cards may only be redeemed toward the purchase of
            eligible products on www.amazon.in. Purchases are deducted from the
            redeemer's Gift Card balance. Any unused Gift Card balance will
            remain associated with the redeemer's Amazon.in account and applied
            to purchases in order of earliest expiration date. If a purchase
            exceeds the redeemer's Gift Card balance, the remaining amount must
            be paid with by credit card, net banking or debit card. No fees or
            charges apply to Gift Cards.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Gift Cards cannot be used to purchase other gift cards. Gift Cards
            cannot be reloaded, resold, transferred for value or redeemed for
            cash. Unused Gift Card balances associated with an Amazon.in account
            cannot be transferred to another amazon.in account. No interest will
            be payable by Amazon on any Gift Card or Gift Card balance.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Amazon.in or Premier Sales Promotions Pvt Ltd. is not responsible if
            a Gift Card is lost, stolen, destroyed or used without permission.
            Amazon Seller Services Private Limited ("Amazon") will have the
            right to close customer accounts and take payment from alternative
            forms of payment if a fraudulently obtained Gift Card is redeemed
            and/or used to make purchases on
            <a href="http://www.amazon.in">
                www.amazon.in
            </a>
            .
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            The Amazon.in Conditions of Use apply to Gift Cards. Amazon.in
            reserves the right to change these Gift Card terms and conditions
            from me to me in its discretion and without prior notice to you. All
            terms and conditions are applicable to the extent permitted by law.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            The Amazon Gift Voucher is Redeemable across all products on Amazon
            except apps, certain global store products and other Amazon.in gift
            cards.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Gift Vouchers once sold are non-refundable, whether you use it or
            not.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            This gift voucher cannot be treated as a cash equivalent under any
            circumstances or interpretation and shall under no circumstances can
            be exchanged for cash.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Validity of Gift Vouchers cannot be extended, new EGVs cannot be
            provided against the expired/unused Vouchers.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Once Amazon code is applied on Amazon India Website or App, it will
            be valid for 6 months for utilization.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            This gift voucher is not valid at any of Amazon.in alliance partner
            website/App or any retail outlets /stores
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            All disputes arising out of or in connection to this scheme are
            subject to exclusive jurisdiction of the courts in Bangalore only.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            EGVs are a Prepaid Instrument subjected to regulations by Reserve
            Bank of India. Premier Sales Promotions Pvt Ltd and its GV issuing
            partners will be legally required to share the details of the
            purchase of the EGVs and transaction undertaken using the EGV. The
            issuer is also required to share the Know Your Customer (“KYC”)
            details of the purchaser/ redeemer of the EGV with RBI or such
            statutory authorities, as per statutory guidelines issued from me to
            me. Premier Sales Promotions Pvt Ltd and its GV issuing partners may
            contact the purchaser of the EGV in this regard.
        </p>
    </li>
</ol>
<p dir="ltr">
    Terms &amp; Conditions - Reliance Digital - Gift Voucher
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            This Gift Voucher is issued by Reliance Retail Limited (Reliance).
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            This Gift Voucher is redeemable, and it is valid only for a period
            of 6 months from the date of receipt.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Gift Voucher can be redeemed only against the purchase of qualified
            products from Reliance Digital Stores in India.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            This Gift Voucher is not valid on purchase of Gold/Silver Coins.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            This Gift Voucher cannot be redeemed in parts and needs to be
            redeemed for full value.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            No extension of the Validity period nor refund of the value of Gift
            Voucher in case of failure to use the Gift Voucher on or before the
            validity period
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Reliance shall not be responsible for loss or misuse of Gift Voucher
            and no duplicate or compensation in lieu of the Gift Voucher shall
            be given by Reliance.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Reliance’s overall liability will not exceed the value of the Gift
            Voucher.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Gift Vouchers can be used to buy discounted products
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Multiple Gift Vouchers can be used in one bill
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Gift Vouchers can be used at all Listed Outlets on
            storelocator.ril.com/Retail
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Gift Vouchers cannot be used online
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Bearer of the Gift Voucher shall be deemed to have read, understood
            and accepted these terms and conditions.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Bearer of the code of Gift Voucher is deemed to be the beneficiary
            of this Gift Voucher. Any redemption by the bearer of Gift Voucher
            shall be considered as a valid discharge of liabilities by Reliance.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Reliance shall not be liable under any circumstances towards any
            loss or damage or expenses or cost or any third-party liability,
            whether directly or indirectly arising out of or in relation to use
            of Gift Voucher and redemption thereunder by the bearer.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Reliance reserves its right to modify/amend the terms and conditions
            of the Gift Voucher at any time without prior notice and such
            modifications shall be binding on the user/bearer of the Gift
            Voucher.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Any dispute with regard to Product liability, guarantee, warranty
            and quality of the Products to be purchased under redemption of the
            Gift Voucher shall be addressed to the respective Manufacturer/
            Brand Owner and Reliance shall not be held responsible/liable for
            the same. The guarantee and warranty of the Products shall be
            transferred, as received from the respective Manufacturer/Buyer.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            In case of any issue faced by the Gift Voucher bearer, the Gift
            Voucher bearer can reach out to Customer service desk at the Stores
            or Customer Care at Toll free number 18008910001/18001027382 or
            Email at
            <a href="mailto:customerservice@ril.com">
                customerservice@ril.com
            </a>
            .
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            The Gift Voucher is binding for all applicable laws of India.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            All disputes are subject to the jurisdiction of courts at Mumbai
            only.
        </p>
    </li>
</ol>


    </Popup>
  );
};

export default TermsPopup;
