import { useTranslation } from "react-i18next";
import Popup from "../../helpers/Popup";
import "./index.scss";

const ContactUsPopup = () => {
  const { t } = useTranslation();

  return (
    <Popup className="contact-us-popup" title="Contact us">
      <p className="text3" style={{ minWidth: "300px" }}>
        <p>For any queries, please contact us at :</p>
        <p >Premier Sales Promotions Pvt. Ltd, Mitra Towers, Bangalore - 560001
          <br /> Email Support: <a href="mailto:feedback@bigcity.in">feedback@bigcity.in</a>
          <br /><a className="contact-text" target="_blank" href="tel:08069261966">
            Helpline number : 080-69261966
          </a>
          </p>
          <p>
          Availability - Monday to Friday 10:30 AM to 5:30 PM (Lines closed on public & bank holidays)
          </p>

      </p>
    </Popup>
  );
};

export default ContactUsPopup;
