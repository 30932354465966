import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";

import { ROUTES } from "./lib/consts";
import API from "./api";
import { useGlobalLoaderContext } from "./helpers/GlobalLoader";
import store from "./store/store";
import { setUserIdentification } from "./store/actions/authAction";
import { logoutUser } from "./lib/utils";
import { useAuthentication } from "./hooks/useAuthentication";

import Home from "./pages/Home/Home";
import Register from "./pages/Register/Register";
import Details from "./pages/Details/Details";
import Gamer from "./pages/Gamer/Gamer";
import Musicbuff from "./pages/Musicbuff/Musicbuff";
import Sports from "./pages/Sports/Sports";
import Explorer from "./pages/Explorer/Explorer";
import Explorergirl from "./pages/Explorergirl/Explorergirl";
import NotAllowed from "./pages/NotAllowed/NotAllowed";
import PrivateRoute from "./helpers/PrivateRoute";


function App() {
  const { showLoader, hideLoader } = useGlobalLoaderContext();
  const { isLoggedIn } = useAuthentication();
  const { search } = useLocation();
  const [isParamValid, setIsParamValid] = useState<boolean>(false);

  const navigate = useNavigate();

  const validUtmMediums = [
    "explorer-girl",
    "fashionista",
    "gamer",
    "music-buff",
    "sports-lover",
    "explorer-boy",
  ];

  useEffect(() => {
    const urlParams = new URLSearchParams(search);
    const utmSource = urlParams.get("utm_source");
    const utmMedium = urlParams.get("utm_medium");

    if (utmSource === "qrcode" && validUtmMediums.includes(utmMedium || "")) {
      setIsParamValid(true);
      API.initialize(showLoader, hideLoader);
      API.createUser().then((response: any) => {
        store.dispatch(setUserIdentification(response));
        if (!response.isLoggedIn && isLoggedIn) {
          logoutUser();
          toast.info("Your session has expired");
        }
      });
    } else {
      setIsParamValid(false);
    }
  }, []);

  useEffect(() => {
    window.onpopstate = () => {
      logoutUser();
      navigate(ROUTES.HOME);
      toast.info("Your session has expired");
    };
  }, []);

  return (
    <div className="App">
      {isParamValid ? (
        <Routes>
          <Route path={ROUTES.HOME} element={
            <Home />
            } />
          <Route path={ROUTES.REGISTER} element={
            <Register />
            } />
          <Route path={ROUTES.DETAILS} element={
             <PrivateRoute>
            <Details />
             </PrivateRoute>
            } />
          <Route path={ROUTES.GAMER} element={
             <PrivateRoute>
            <Gamer />
             </PrivateRoute>
            } />
          <Route path={ROUTES.MUSIC} element={
             <PrivateRoute>
            <Musicbuff />
             </PrivateRoute>
            } />
          <Route path={ROUTES.SPORTS} element={
             <PrivateRoute>
            <Sports />
             </PrivateRoute>
            } />
          <Route path={ROUTES.EXPLORER} element={
             <PrivateRoute>
            <Explorer />
             </PrivateRoute>
            } />
          <Route path={ROUTES.EXPLORERGIRL} element={
             <PrivateRoute>
            <Explorergirl />
             </PrivateRoute>
            } />
        </Routes>
      ) : (
        <NotAllowed />
      )}
      <ToastContainer position="bottom-center" hideProgressBar />
    </div>
  );
}

export default App;
